<template>
  <v-app :key="componentKey">
    <v-navigation-drawer permanent app v-if="drawer && authInfo && authInfo.user">
      <v-list>
        <v-list-item class="px-2">
          <v-img max-width=250 src="@/assets/oriente.png"></v-img>
        </v-list-item>

        <v-list-item v-if="authInfo.user" link>
          <v-list-item-content>
            <v-list-item-title class="title">
              BIENVENIDO! <br>
              <h5>{{ authInfo.user.full_name_honorific }}</h5>
            </v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <h3 v-if="selectedDoyang">{{ selectedDoyang.name }}</h3>
      <v-divider></v-divider>
      <v-expansion-panels>
        <v-expansion-panel v-if="authInfo.user">
          <v-expansion-panel-header>
            Mi cuenta
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list nav dense>
              <v-list-item :to="{ name: 'Home' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Inicio</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'UserPassword' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-key</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cambiar contraseña</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout" link>
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Salir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="authInfo.user && (hasPermission('Taekwondo,Doyangs,Multinivel Profesores,Tablas Comisiones,Grados,Alumnos,Sedes,Honorificos,Localidades,Productos,Sinodales,Categories de doyang,Validación de examen,Otros requisitos,Tareas bimestrales,Permisos') || (ENV.permissions.can_see_catalogos.includes(parseInt(authInfo.user.role_id))))">
          <v-expansion-panel-header>
            Catálogos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list nav dense>
              <v-list-item :to="{ name: 'GenealogyIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Linaje</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Usuarios')" :to="{ name: 'ProfessorIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyangs')" :to="{ name: 'DoyangIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Doyangs</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Multinivel Profesores')" :to="{ name: 'DoyangSurrogationIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-family-tree</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Multinivel Profesores</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Tablas Comisiones')" :to="{ name: 'MultilevelDiscountTypeIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-family-tree</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Tablas Comisiones</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Grados')" :to="{ name: 'DegreeIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-seal</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Grados</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Alumnos')"
                :to="{ name: 'StudentIndex', params: { generalDirection: true } }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Alumnos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Sedes')" :to="{ name: 'VenueIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-map-marker-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sedes</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Honorificos')" :to="{ name: 'HonorificTitleIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-school</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Honoríficos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Localidades')" :to="{ name: 'CityIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-home-city</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Localidades</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Productos')" :to="{ name: 'ProductIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-package-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Productos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Sinodales')" :to="{ name: 'SinodalIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sinodales</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Categories de doyang')" :to="{ name: 'DoyangCategoryIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-shape-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Categorías de Doyang</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Validación de examen')" :to="{ name: 'RequirementIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Validación de examen</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Otros requisitos')" :to="{ name: 'OtherExamRequisiteIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Otros requisitos de examen</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Tareas bimestrales')" :to="{ name: 'MonthlyDutyIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Tareas bimestrales</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Permisos')" :to="{ name: 'PermissionIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Permisos</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        v-if="authInfo.user && ENV.permissions.can_see_taekwondo.includes(parseInt(authInfo.user.role_id))">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Taekwondo
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list nav dense>
              <v-list-item v-if="hasPermission('Torneos', 'update')" :to="{ name: 'ParentEventIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-notebook</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Eventos Padre</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Torneos')" :to="{ name: 'TournamentIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-trophy</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Torneos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Torneos', 'update')" :to="{ name: 'TournamentCategoryIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-notebook</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Categorías de torneos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Eventos')" :to="{ name: 'EventIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-calendar-range-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Eventos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Examen')" :to="{ name: 'ExamIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-notebook</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Examenes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        v-if="authInfo.user && ENV.permissions.can_see_administration.includes(parseInt(authInfo.user.role_id))">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Configuración
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list nav dense>
              <v-list-item :to="{ name: 'ConfigurationIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Configuración</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels v-if="canSeeGeneralDirection || (authInfo.user &&
        (authInfo.user.doyangs_as_responsible || authInfo.user.doyangs_as_personal) &&
        selectedDoyang &&
        (
          authInfo.user.doyangs_as_responsible.reduce((accum, doyang) => accum || doyang.id == selectedDoyang.id, false) ||
          authInfo.user.doyangs_as_personal.reduce((accum, doyang) => accum || doyang.id == selectedDoyang.id, false)
        ))">
        <v-expansion-panel>
          <strong>DOYANG</strong>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Mi Doyang
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list nav dense>
              <v-list-item :to="{ name: 'Statistics', params: { id: selectedDoyang.id } }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Estadística</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyang Alumnos')"
                :to="{ name: 'StudentIndex', params: { doyangId: selectedDoyang.id } }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Alumnos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyang Grupos')" :to="{ name: 'DoyangGroupIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Grupos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyang Examen')" :to="{ name: 'ExamIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Exámenes</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyang Torneos')" :to="{ name: 'TournamentIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Torneos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyang Eventos')" :to="{ name: 'EventIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Eventos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Doyang Tareas Bimestrales')" :to="{ name: 'MonthlyDutyIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Tareas bimestrales</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Administración
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list nav dense>
              <v-list-item v-if="hasPermission('Recursos Digitales')" :to="{ name: 'GeneralFileResourceIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-laptop</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Recursos Digitales</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Seguros')" :to="{ name: 'InsuranceIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-lock</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Seguros</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Ventas')" :to="{ name: 'PaymentCreate' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Ventas</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item v-if="hasPermission('Gastos')" :to="{ name: 'ExpenseCreate' }" link> -->
              <v-list-item :to="{ name: 'ExpenseIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-wallet</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Gastos</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Contabilidad')" :to="{ name: 'PaymentIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Contabilidad</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Inventario')"
                :to="{ name: 'InventoryIndex', params: { id: selectedDoyang.id } }" link>
                <v-list-item-icon>
                  <v-icon>mdi-package-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Inventario</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Entradas/Salidas')"
                :to="{ name: 'InventoryMoveIndex', params: { id: selectedDoyang.id } }" link>
                <v-list-item-icon>
                  <v-icon>mdi-clipboard-list</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Entradas/Salidas</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('Recibos')" :to="{ name: 'ReceiptIndex' }" link>
                <v-list-item-icon>
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Recibos</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-row style="position:fixed; z-index:999;">
        <v-col class="text-left">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-col>
      </v-row>
      <!-- Provides the application the proper gutter -->
      <!-- If using vue-router -->
      <router-view v-if="!loading"></router-view>
      <v-progress-circular :size="200" :width="10" v-else color="green lighten-2" indeterminate />
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>
<script>
import ENV from '../env'
export default {
  mounted() {
    this.drawer = !this.isMobile()
  },
  data() {
    return {
      ENV,
      drawer: true,
      componentKey: 0,
    }
  },
  created() {
    document.title = "Sistema MDK Oriente";
    this.componentKey += 1;
  },
  methods: {
    hasPermission(name, action = false) {
      const names = name.split(",")
      if (names.length > 1) {
        let hasPermission = false
        names.forEach(name => {
          if (hasPermission) return;
          hasPermission = this.hasPermission(name, action)
        })
        return hasPermission
      }
      if (!name) return false;
      const permissions = this.authInfo?.user?.permissions
      if (!permissions) return false
      const singlePermissionkey = Object.keys(permissions).filter(key => {
        return permissions[key].name === name
      })?.[0]
      const singlePermission = permissions?.[singlePermissionkey]
      if (!singlePermissionkey) return false
      if (!action && singlePermission) {
        return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
      }
      return singlePermission?.[action] == 1 || false
    },
    isMobile() {
      if (screen.width <= 800) {
        return true;
      }
      else {
        return false;
      }
    },
    logout() {
      this.$store.dispatch('Auth/logout')
      this.$router.push({ name: 'Login' })
    },
    unselectDoyang() {
      this.$store.commit('Auth/selectDoyang', null)
      this.$router.push({ name: 'Home' })
    }
  },
  computed: {
    loading() {
      return this.$store.getters['Global/loading']
    },
    canSeeGeneralDirection() {
      if (!this.authInfo) return false
      return ENV.permissions.can_see_general_direction.includes(parseInt(this.authInfo?.user?.role_id))
    },
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    selectedDoyang() {
      if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && (this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
        return {
          name: 'SIN DOYANG SELECCIONADO'
        }
      } else if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && !(this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
        return {
          name: 'SIN DOYANG SELECCIONADO'
        }
      }
      return this.$store.getters['Auth/selectedDoyang']
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
      }
      return 1000
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.cursor-pointer {
  cursor: pointer;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
